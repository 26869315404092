import React from 'react';
import Logo from '../../elements/logo/Logo';
import Nav from '../../common/header/Nav';
import StickyHeader from './StickyHeader';

const HeaderOne = () => {

    const sticky = StickyHeader(100);

    return (
        <>
            <header className="header axil-header header-style-1">
                <div className={`axil-mainmenu ${sticky ? "axil-sticky" : ""}`}>
                    <div className="container">
                        <div className="header-navbar">
                            <div className="header-logo">
                                <Logo limage="/images/logo.svg"
                                    dimage="/images/logo.svg"
                                    simage="/images/logo.svg"
                                />
                            </div>
                            <div className="header-main-nav">
                                <Nav />
                            </div>

                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}

export default HeaderOne;